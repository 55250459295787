import React from 'react'

function AstroIcon({ wrapperClassName }) {
  return (
    <svg  viewBox="0 0 256 366" version="1.1" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" className={`${wrapperClassName}`}>
      <g>
          <path d="M182.021899,9.14729513 C185.003554,12.8489818 186.524039,17.8439599 189.565008,27.8338751 L256,246.07324 C231.437035,233.330949 204.734675,224.140955 176.574007,219.182711 L133.318122,73.008385 C132.610445,70.6165977 130.413371,68.9758488 127.919318,68.9758488 C125.41872,68.9758488 123.217965,70.625188 122.515605,73.0251566 L79.7837287,219.10908 C51.4921611,224.044826 24.6679819,233.251591 0,246.040106 L66.760821,27.7833559 C69.8111986,17.8106213 71.336592,12.8241926 74.3186562,9.1293373 C76.9509722,5.86739262 80.3752328,3.334082 84.2645972,1.77078902 C88.6701983,0 93.8845156,0 104.313559,0 L151.997135,0 C162.439677,0 167.660949,0 172.07064,1.77448285 C175.963277,3.34101561 179.389583,5.87945996 182.021899,9.14729513 Z" fill="#A3B3BC"></path>
          <path d="M189.971616,256.459332 C179.019791,265.824405 157.15991,272.211095 131.980489,272.211095 C101.075831,272.211095 75.1731874,262.589949 68.2997134,249.65049 C65.8424761,257.066381 65.2914691,265.553606 65.2914691,270.975318 C65.2914691,270.975318 63.6724005,297.597911 82.1890151,316.114934 C82.1890151,306.499925 89.9836974,298.705652 99.598298,298.705652 C116.078191,298.705652 116.059783,313.083374 116.044648,324.748195 C116.044239,325.097534 116.04383,325.444828 116.04383,325.789258 C116.04383,343.494293 126.864755,358.671732 142.253679,365.069057 C139.955158,360.341524 138.665794,355.032714 138.665794,349.422424 C138.665794,332.536332 148.579419,326.249044 160.101068,318.941555 C169.268154,313.127552 179.453806,306.668049 186.473315,293.709774 C190.13565,286.948792 192.215323,279.204833 192.215323,270.975318 C192.215323,265.914399 191.429105,261.037149 189.971616,256.459332 Z" fill="#A3B3BC"></path>
      </g>
    </svg>
  )
}

export default AstroIcon