import React from 'react'

function DjangoIcon({  wrapperClassName }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      className={`${wrapperClassName}`}
    >
      <path
        d='M 6.0449219 15.990234 L 6.0449219 20.220703 C 5.6369219 20.115703 5.147125 20.080078 
        4.703125 20.080078 C 1.844125 20.080078 5.1209037e-17 21.872766 0 24.634766 C 0 27.502766 
        1.7380781 28.994047 5.0800781 28.998047 C 6.1960781 28.998047 7.2298594 28.895859 8.6308594 
        28.630859 L 8.6308594 15.990234 L 6.0449219 15.990234 z M 10.246094 16.003906 L 10.246094 
        18.783203 L 12.982422 18.783203 L 12.982422 16.003906 L 10.246094 16.003906 z M 10.246094 20 
        L 10.246094 25.615234 C 10.246094 27.973234 10.123422 28.870531 9.7324219 29.644531 C 9.3594219 
        30.401531 8.7744063 30.896031 7.5664062 31.457031 L 10.105469 32.654297 C 11.313469 32.053297 
        11.897797 31.510437 12.341797 30.648438 C 12.816797 29.752437 12.978516 28.712781 12.978516 
        26.550781 L 12.978516 20 L 10.246094 20 z M 45.566406 20 C 42.757406 20 41.005 21.761891 41 
        24.587891 C 41 27.309891 42.698078 29 45.455078 29 C 48.247078 29 50 27.240641 50 24.431641 
        C 50 21.691641 48.319406 20 45.566406 20 z M 18.083984 20.080078 C 16.873984 20.080078 15.766422 
        20.327719 14.607422 20.886719 L 14.611328 22.974609 C 15.635328 22.431609 16.725313 22.150391 
        17.695312 22.150391 C 18.357313 22.150391 18.597656 22.328359 18.597656 22.818359 L 18.597656 
        23.412109 C 15.377656 23.690109 14.066406 24.566938 14.066406 26.460938 C 14.066406 28.211938 
        15.053906 29 17.253906 29 C 18.341906 29 19.825 28.875172 21 28.701172 L 21 23.21875 C 21 
        22.09675 20.928984 21.570969 20.708984 21.167969 C 20.316984 20.447969 19.431984 20.080078 
        18.083984 20.080078 z M 26.917969 20.080078 C 25.569969 20.080078 24.239047 20.271359 22.623047 
        20.693359 L 22.623047 28.890625 L 25.355469 28.890625 L 25.355469 22.453125 C 25.905469 22.293125 
        26.241234 22.259766 26.615234 22.259766 C 27.590234 22.259766 28.046875 22.646547 28.046875 23.685547 
        L 28.046875 28.890625 L 30.683594 28.890625 L 30.683594 23.578125 C 30.683594 22.225125 30.502547 
        21.590719 29.935547 21.011719 C 29.333547 20.397719 28.318969 20.080078 26.917969 20.080078 z M 
        37.785156 20.095703 C 33.792156 20.095703 31.535156 21.834062 31.535156 24.914062 C 31.535156 
        27.394063 33.059031 28.960938 35.457031 28.960938 C 36.165031 28.960937 36.700422 28.853797 
        37.232422 28.591797 L 37.232422 28.642578 C 37.232422 30.365578 36.467781 31.087891 34.675781 
        31.087891 C 33.660781 31.087891 32.703094 30.861141 31.746094 30.369141 L 31.744141 30.369141 L 
        31.744141 32.871094 C 32.702141 33.241094 33.624406 33.398438 34.691406 33.398438 C 36.787406 
        33.398438 38.263234 32.745734 39.115234 31.427734 C 39.699234 30.528734 39.896484 29.438234 
        39.896484 27.115234 L 39.896484 26.376953 C 39.878484 26.006953 39.879328 25.637578 39.861328 
        25.267578 L 39.84375 23.789062 L 39.808594 22.644531 L 39.808594 22.361328 C 40.357594 22.413328 
        39.689969 22.295547 40.417969 22.435547 L 41.355469 20.482422 C 40.042469 20.217422 38.958156 
        20.095703 37.785156 20.095703 z M 45.527344 22.158203 C 46.583344 22.158203 47.189453 23.029094 
        47.189453 24.496094 C 47.189453 25.980094 46.566766 26.835938 45.509766 26.835938 C 44.438766 
        26.835938 43.808594 25.980094 43.808594 24.496094 C 43.808594 23.012094 44.434344 22.158203 
        45.527344 22.158203 z M 4.9707031 22.259766 C 5.3081071 22.259766 5.7231991 22.295822 6.0429688 
        22.400391 L 6.0429688 26.748047 C 5.6519687 26.800047 5.256125 26.818359 4.953125 26.818359 C 
        3.552125 26.818359 2.7871094 26.022031 2.7871094 24.582031 C 2.7871094 23.103031 3.5867031 22.259766 
        4.9707031 22.259766 z M 36.894531 22.277344 L 37.091797 22.277344 L 37.179688 22.277344 L 37.179688 
        22.486328 L 37.197266 23.382812 L 37.214844 24.632812 C 37.214844 25.001813 37.232422 25.354875 
        37.232422 25.671875 L 37.232422 26.410156 L 37.232422 26.607422 C 36.880422 26.712422 36.578078 
        26.78125 36.205078 26.78125 C 34.997078 26.78125 34.320312 26.005344 34.320312 24.652344 C 34.320313 
        23.703344 34.6575 23.034063 35.3125 22.664062 C 35.7385 22.399063 36.362531 22.260344 36.894531 
        22.277344 z M 18.716797 25.177734 L 18.716797 26.984375 C 18.206797 27.071375 17.847078 27.107422 
        17.455078 27.107422 C 16.738078 27.107422 16.414062 26.860937 16.414062 26.335938 C 16.414062 
        25.634938 16.979797 25.351734 18.716797 25.177734 z'
      />
    </svg>
  )
}

export default DjangoIcon