import React from 'react'

function IosIcon({ wrapperClassName }) {
  return (
    <>
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        className={`${wrapperClassName}`}
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#A3B3BC" stroke="none">
          <path d="M886 5109 c-410 -61 -741 -357 -854 -765 l-27 -99 0 -1685 0 -1685
            27 -99 c102 -370 374 -641 747 -744 l96 -27 1685 0 1685 0 100 27 c368 102
            641 376 743 747 l27 96 0 1685 0 1685 -27 96 c-102 371 -375 645 -743 746
            l-100 28 -1650 1 c-907 1 -1677 -2 -1709 -7z m1451 -893 c51 -26 87 -69 143
            -171 31 -56 57 -92 62 -87 5 5 37 56 71 113 50 83 71 109 108 132 99 60 215
            43 292 -43 70 -77 83 -170 37 -260 -10 -19 -239 -417 -509 -885 l-491 -850
            397 -5 c445 -6 434 -4 516 -79 66 -61 101 -146 101 -241 0 -47 -6 -89 -17
            -113 l-16 -38 -1083 3 c-1019 3 -1085 4 -1123 21 -175 79 -191 318 -28 414
            l48 28 333 5 333 5 379 657 380 657 -124 213 c-67 117 -129 230 -136 252 -26
            81 12 198 81 247 71 51 177 61 246 25z m888 -1466 l340 -589 321 -1 c359 0
            385 -4 447 -67 52 -51 71 -98 71 -168 -1 -91 -46 -164 -129 -208 -36 -19 -61
            -22 -236 -27 l-197 -5 119 -205 c69 -120 122 -224 128 -250 30 -143 -83 -280
            -233 -280 -48 0 -132 37 -162 71 -31 35 -920 1577 -944 1639 -66 167 -58 370
            20 535 27 57 93 145 109 145 3 0 159 -265 346 -590z m-1734 -1177 c35 -18 83
            -51 106 -75 l42 -43 -117 -203 c-151 -261 -176 -288 -287 -299 -62 -6 -108 7
            -164 47 -71 52 -108 163 -81 246 13 39 162 307 186 333 19 21 73 30 165 28 74
            -3 96 -8 150 -34z"/>
        </g>
      </svg>
    </>
  )
}

export default IosIcon