import React from 'react'

function IonicIcon({ wrapperClassName }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      className={`${wrapperClassName}`}
    >
      <path
        d="M 25 2 C 12.318 2 2 12.317 2 25 C 2 37.683 12.318 48 25 48 C 37.682 48 48 37.683 48 25 C 48 21.575 47.241578 18.324391 45.892578 
        15.400391 C 44.967578 16.911391 43.528453 18.073859 41.814453 18.630859 C 42.568453 20.614859 43 22.755 43 25 C 43 34.925 34.925 43 
        25 43 C 15.075 43 7 34.925 7 25 C 7 15.075 15.075 7 25 7 C 27.7 7 30.252734 7.6135937 32.552734 8.6835938 C 33.227734 7.0235937 
        34.480547 5.6617969 36.060547 4.8417969 C 32.776547 3.0317969 29.007 2 25 2 z M 39.5 6 A 5.5 5.5 0 0 0 34 11.5 A 5.5 5.5 0 0 0 39.5 
        17 A 5.5 5.5 0 0 0 45 11.5 A 5.5 5.5 0 0 0 39.5 6 z M 25 15 A 10 10 0 0 0 15 25 A 10 10 0 0 0 25 35 A 10 10 0 0 0 35 25 A 10 10 0 0 
        0 25 15 z"/>
    </svg>
  )
}

export default IonicIcon