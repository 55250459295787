import React from 'react'

function ApolloClientIcon({ wrapperClassName="" }) {
  return (
    <svg className={wrapperClassName} preserveAspectRatio="xMidYMid" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
      <path 
        d="m160.227 178.5186h27.63l-44.107-114.47h-30.549l-44.107 114.47h27.632l7.208-19.39h41.675l-7.544-21.456h-27.44l17.85-49.254zm91.112-84.7506c-.982-3.536-4.634-5.613-8.185-4.627-3.537.982-5.61 4.646-4.628 8.183 2.773 9.985 4.178 20.306 4.178 30.676 0 63.248-51.456 114.702-114.704 114.702s-114.703-51.454-114.703-114.702c0-63.249 51.455-114.704 114.703-114.704 26.793 0 52.718 9.518 73.179 26.456-.796 1.9-1.238 3.985-1.238 6.173 0 8.835 7.162 15.997 15.997 15.997s15.997-7.162 15.997-15.997-7.162-15.997-15.997-15.997c-1.701 0-3.338.271-4.876.763-23.04-19.635-52.549-30.691-83.062-30.691-70.579 0-128 57.42-128 128 0 70.579 57.421 127.999 128 127.999s128-57.42 128-127.999c0-11.572-1.567-23.09-4.661-34.232z"
        fill="#A3B3BC"
        />
    </svg>
  )
}

export default ApolloClientIcon