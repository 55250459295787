import React from 'react'

function FormikIcon({ wrapperClassName='' }) {
  return (
    <svg 
      viewBox="0 0 34.7 34.7"
      xmlns="http://www.w3.org/2000/svg"
      className={wrapperClassName}
    >
      <path d="m-.0001948.00485276h34.699667v34.699667h-34.699667z" strokeWidth=".1187196918"/>
      <g fill="#A3B3BC" transform="matrix(.400018502 0 0 .400018502 5.34987738 .55385626)">
        <path d="m38.869 0 9.694 5.5746-38.176 21.954-9.694-5.5747z"/>
        <path d="m48.954 6.7451-37.936 21.806-.0013 11.032 37.936-21.806z"/>
        <path d="m33.698 28.276-22.601 12.991-.0012 11.033 22.601-12.991z"/>
        <path d="m25.035 45.717-13.99 8.0421-.0013 11.033 13.991-8.0421z"/>
        <path d="m.0037583 23.017 9.751 5.6047-.00375 11.035-9.751-5.6046z"/>
        <path d="m.096543 35.657 9.6327 5.5367-.00376 11.035-9.6327-5.5366z"/>
        <path d="m.092298 48.221 9.6359 5.5386-.00401 11.763-9.6359-5.5386z"/>
        <path d="m21.564 84-9.694-5.5746 37.743-21.704 9.6939 5.5746z"/>
        <path d="m11.102 77.471 37.879-21.773.0012-11.032-37.879 21.773z"/>
        <path d="m26.3 55.974 22.601-12.991.0013-11.033-22.601 12.991z"/>
        <path d="m34.964 38.532 13.99-8.0421.0012-11.033-13.99 8.0421z"/>
        <path d="m59.996 61.306-9.7509-5.6046.0037-11.035 9.751 5.6046z"/>
        <path d="m59.904 48.595-9.6327-5.5367.0037-11.035 9.6327 5.5366z"/>
        <path d="m59.908 36.031-9.636-5.5386.0039-11.552 9.636 5.5385z"/>
      </g>
    </svg>
  )
}

export default FormikIcon