import React from 'react'

function GitIcon ({ wrapperClassName }) {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className={`${wrapperClassName}`}>
      <title/>
      <g id="Git">
        <path d="M23.56,10.93,13.06.43a1.54,1.54,0,0,0-2.12,0l-2,2a.5.5,0,0,0,0,.71L10.74,5a.5.5,0,0,0,.54.11A2,2,0,0,1,14,7a2,2,0,0,1-.15.74.5.5,0,0,0,.11.54L15.72,10a.5.5,0,0,0,.54.11A2,2,0,1,1,15,12a2,2,0,0,1,.14-.71.5.5,0,0,0-.11-.53L13.35,9.07a.5.5,0,0,0-.85.35v5.28a.5.5,0,0,0,.3.46,2,2,0,1,1-1.6,0,.5.5,0,0,0,.3-.46V9.28a.5.5,0,0,0-.3-.46,2,2,0,0,1-1.06-2.56A.5.5,0,0,0,10,5.73L8.19,3.88a.5.5,0,0,0-.71,0l-7,7a1.5,1.5,0,0,0,0,2.12l10.5,10.51a1.5,1.5,0,0,0,2.12,0l10.5-10.51a1.5,1.5,0,0,0,0-2.12Z" style={{fill: "#A3B3BC"}}/>
      </g>
      </svg>
  )
}

export default GitIcon;

